<template>
  <div id="rebate_report">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.rebateReport') }}</h2>

          <p></p>
        </div>

        <div class="rebate_report_calendar">
          <!-- 提交部分 -->

          <div class="calendar_content_main clearfix">
            <div class="calendar_content_main_left">
              <div class="top">
                <el-form>
                  <AccountNumber @accountCallback="init"></AccountNumber>
                </el-form>

                <div class="left">
                  <el-cascader
                    expand-trigger="hover"
                    :options="instrumentOptions"
                    v-model="selectedInstrument"
                    :show-all-levels="false"
                  ></el-cascader>
                </div>
              </div>

              <div class="bottom">
                <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
              </div>
            </div>

            <div class="calendar_content_main_right">
              <loading-button
                class="purple_button"
                :callback="queryRebateReport"
                text="common.keys.UPDATE"
              ></loading-button>

              <el-button class="purple_button" :callback="download" :disabled="!operateAmountPermisison">{{
                $t('common.button.download')
              }}</el-button>
            </div>
          </div>

          <!-- 4个盒子 -->

          <div class="calendar_box">
            <ul class="clearfix">
              <li class="special">
                <div class="top">
                  <p>{{ $t('report.rebate.others.rebate') }}</p>
                </div>

                <div class="bottom">
                  <p v-if="operateAmountPermisison">
                    <span> {{ currAccountCurrencyFilter }}</span>
                    {{ totalRebate | currency('') }}
                  </p>
                  <p v-else>
                    {{ '***' }}
                  </p>

                  <span>{{ $t('report.rebate.others.totalRebate') }}</span>
                </div>
              </li>

              <li v-for="(summary, index) in summaries" v-bind:key="index">
                <div class="top">
                  <p>{{ summary.category }}</p>
                </div>

                <div class="bottom">
                  <div class="bottom_left">
                    <p v-if="operateAmountPermisison">{{ summary.volume | formatNumber }}</p>
                    <p v-else>
                      {{ '***' }}
                    </p>

                    <span>{{ $t('report.rebate.others.lots') }}</span>
                  </div>

                  <div class="bottom_right">
                    <p v-if="operateAmountPermisison">
                      <span>{{ currAccountCurrencyFilter }}</span>
                      {{ summary.commission | currency('') }}
                    </p>
                    <p v-else>
                      {{ '***' }}
                    </p>

                    <span>{{ $t('report.rebate.others.rebate') }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- 表格部分 -->

        <div class="content_box">
          <div class="select">
            <p>
              {{ $t('report.rebate.others.rebate') }} {{ $t('common.keys.FROM') }}
              {{ startDate | formatDate('DD/MM/YYYY') }} {{ $t('common.keys.TO') }}
              {{ endDate | formatDate('DD/MM/YYYY') }}
            </p>
          </div>

          <div class="table_box">
            <el-table
              :data="displayData"
              ref="summaryTable"
              :default-sort="{ prop: 'totalrebate', order: 'descending' }"
              @sort-change="sortChange"
            >
              <el-table-column :label="$t('report.rebate.column.name')" style="word-wrap: normal" min-width="100">
                <template slot-scope="scope">
                  <a
                    @click.stop.prevent="
                      dialogVisible = true;
                      viewTradeDetail = { name: scope.row.name, mt4Account: scope.row.number };
                    "
                    data-testid="name"
                  >
                    {{ scope.row.name }}
                  </a>
                </template>
              </el-table-column>

              <el-table-column
                prop="number"
                :label="$t('report.rebate.column.accountNumber')"
                min-width="100"
              ></el-table-column>

              <el-table-column :label="$t('report.rebate.column.accountType')" min-width="100">
                <template slot-scope="scope">{{ $config.accountTypeMaps[scope.row.type] }}</template>
              </el-table-column>

              <el-table-column
                :label="$t('common.products.forex')"
                sortable
                sort-by="forex.rebate"
                :sort-orders="['ascending', 'descending']"
              >
                <el-table-column :label="$t('report.rebate.column.volume')" min-width="70">
                  <template slot-scope="scope">
                    <span>{{ scope.row.forex.volume | formatNumber }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.forex.rebate | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column
                :label="$t('common.products.commodities')"
                sortable
                sort-by="commodities.rebate"
                :sort-orders="['ascending', 'descending']"
              >
                <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.commodities.volume | formatNumber }}</span>

                    <p style="border-right: 1px solid red"></p>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('report.rebate.column.rebate')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.commodities.rebate | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column
                :label="$t('common.products.indices')"
                sortable
                sort-by="indices.rebate"
                :sort-orders="['ascending', 'descending']"
              >
                <el-table-column :label="$t('report.rebate.column.volume')" min-width="70">
                  <template slot-scope="scope">
                    <span>{{ scope.row.indices.volume | formatNumber }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.indices.rebate | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column
                :label="$t('report.rebate.column.totalRebate')"
                sortable
                sort-by="totalrebate"
                :sort-orders="['ascending', 'descending']"
                min-width="150"
              >
                <template slot-scope="scope">
                  <span>{{ currAccountCurrencyFilter }}{{ scope.row.totalrebate | currency('') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 分页 -->

          <el-row type="flex" class="row-bg" justify="end" style="padding-top: 38px">
            <pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></pagination>
          </el-row>
        </div>
      </div>
    </div>

    <Dialog :dialogVisible.sync="dialogVisible" :viewTradeDetail="viewTradeDetail"></Dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import LoadingButton from '@/components/LoadingButton';
import DateRangePicker from '@/components/DateRangePicker';
import Dialog from '@/components/rebateReport/Dialog';
import AccountNumber from '@/components/form/AccountNumber';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import { apiRebate_report, apiGet_instruments, apiRebatePayerTradeDetails } from '@/resource';
import { defaultVariableMapping } from '@/components/profile/SubProfile.js';

export default {
  components: { Pagination, LoadingButton, DateRangePicker, Dialog, AccountNumber },
  data() {
    return {
      startDate: '',
      endDate: '',
      userID: this.$store.state.common.CUID,
      dialogVisible: false,
      totalRebate: 0,
      summaries: [
        {
          category: this.$t('common.products.forex'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.commodities'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.indices'),
          commission: 0,
          volume: 0
        }
      ],
      viewTradeDetail: { name: null, mt4Account: null },
      selectedInstrument: [null],
      instrumentOptions: [
        {
          value: null,
          label: this.$t('report.rebate.dropdown.allInstruments')
        },
        {
          value: 'forex',
          label: this.$t('common.products.forex'),
          children: []
        },
        {
          value: 'commodities',
          label: this.$t('common.products.commodities'),
          children: []
        },
        {
          value: 'indices',
          label: this.$t('common.products.indices'),
          children: []
        },
        {
          value: 'crypto',
          label: this.$t('common.products.crypto'),
          children: []
        }
      ],
      // 分页
      displayData: [],
      sorting: { column: 'totalrebate', order: 'descending' },
      tableData: [],
      currAccountCurrencyFilter: '',
      updateTimeRecord: { startDate: this.startDate, endDate: this.endDate }
    };
  },
  watch: {
    dataSourceId() {
      this.queryInstruments();
    }
  },
  methods: {
    async download() {
      if (!this.tableData.length) {
        this.$message({
          message: this.$t('report.nodataMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }
      let days = moment(this.endDate).diff(moment(this.startDate), 'days');
      const MAX_DAYS = 90;
      const fileName = `rebate_report_${moment(this.updateTimeRecord.startDate).format('YYYY-MM-DD')}_${moment(
        this.updateTimeRecord.endDate
      ).format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD HH:mm:ss A')}`;
      if (days > MAX_DAYS) {
        this.$message({
          message: this.$t('report.timeLimitMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }

      const rebateHeader = [
        'home.Name',
        'home.Account',
        'common.keys.ACCTYPE',
        `${this.$t('home.TOTAL_VOLUME')}(${this.$t('common.products.forex')})`,
        `${this.$t('home.Rebate')}(${this.$t('common.products.forex')})`,
        `${this.$t('home.TOTAL_VOLUME')}(${this.$t('common.products.commodities')})`,
        `${this.$t('home.Rebate')}(${this.$t('common.products.commodities')})`,
        `${this.$t('home.TOTAL_VOLUME')}(${this.$t('common.products.indices')})`,
        `${this.$t('home.Rebate')}(${this.$t('common.products.indices')})`,
        `${this.$t('home.TOTAL_VOLUME')}(${this.$t('common.products.crypto')})`,
        `${this.$t('home.Rebate')}(${this.$t('common.products.crypto')})`,
        `${this.$t('home.TOTAL_VOLUME')}(shareCFD)`,
        `${this.$t('home.Rebate')}(shareCFD)`,
        `${this.$t('home.TOTAL_VOLUME')}(bond)`,
        `${this.$t('home.Rebate')}(bond)`,
        this.$t('report.rebate.column.totalRebate')
      ].map(key => this.$t(key));
      const rebateAccountHeader = [
        'home.Account',
        'home.Name',
        'common.keys.DATE',
        'report.rebate.column.instrument',
        'home.TOTAL_VOLUME',
        'report.rebate.column.notionalvalue',
        'home.Rebate'
      ].map(key => this.$t(key));
      const tradeHistory = await this.queryAllRebateDetails();
      let option = {
        fileName,
        datas: [
          {
            sheetData: this.parseExcelData(this.tableData),
            sheetName: 'Rebate',
            sheetHeader: rebateHeader,
            columnWidths: [5, 7, 6, 6, 7, 8, 8, 7, 7, 7, 6, 8, 7, 6, 7, 8]
          },
          {
            sheetData: tradeHistory,
            sheetName: 'Account Rebate',
            sheetHeader: rebateAccountHeader,
            columnWidths: [5, 7, 6, 6, 7, 8, 8]
          }
        ]
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      return Promise.resolve();
    },
    parseExcelData(tableData) {
      return tableData.map(item => {
        const { name, number, type, forex, commodities, indices, crypto, sharecfd, bond, totalrebate } = item;
        return {
          name,
          number,
          type: this.$config.accountTypeMaps[type],
          'Volume(forex)': forex.volume,
          'Rebate(forex)': forex.rebate,
          'Volume(commodities)': commodities.volume,
          'Rebate(commodities)': commodities.rebate,
          'Volume(indices)': indices.volume,
          'Rebate(indices)': indices.rebate,
          'Volume(crypto)': crypto.volume,
          'Rebate(crypto)': crypto.rebate,
          'Volume(sharecfd)': sharecfd.volume,
          'Rebate(sharecfd)': sharecfd.rebate,
          'Volume(bond)': bond.volume,
          'Rebate(bond)': bond.rebate,
          totalrebate
        };
      });
    },
    queryAllRebateDetails() {
      const mt4AccountSum = this.tableData.map(item => item.number).join(',');
      return apiRebatePayerTradeDetails({
        agentAccount: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        mt4AccountSum: mt4AccountSum,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(res => {
        if (res.data.code === 305) {
          this.totalRebate = 0;
          this.tableData = [];
          this.currAccountCurrencyFilter = '';
          this.summaries.forEach(s => {
            s.commission = s.volume = 0;
          });
        } else {
          return res.data.tradeHistory.map(item => {
            const { accountNumber, name, closeDate, symbol, volume, notionalValue, commission } = item;
            return {
              accountNumber,
              name,
              closeDate: moment(closeDate).format('YYYY-MM-DD'),
              symbol,
              volume,
              notionalValue,
              commission
            };
          });
        }
      });
    },
    queryRebateReport() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }

      return apiRebate_report({
        account: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(response => {
        if (response.data.code === 305) {
          this.totalRebate = 0;
          this.tableData = [];
          this.currAccountCurrencyFilter = '';
          this.summaries.forEach(s => {
            s.commission = s.volume = 0;
          });
        } else {
          this.totalRebate = response.data.total;
          let keyMap = { 'share cfd': 'sharecfd' };
          this.tableData = response.data.payerSummaries.map(item => {
            var objs = Object.keys(item).reduce((newData, key) => {
              let newKey = keyMap[key] || key;
              newData[newKey] = item[key];
              return newData;
            }, {});
            return objs;
          });

          this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);

          this.sortData();
          this.summaries.forEach(s => {
            s.commission = s.volume = 0;
            for (let key in response.data.summaries) {
              let temp = key.toLowerCase();
              temp = this.$t('common.products.' + temp);
              if (temp === s.category) {
                s.commission = response.data.summaries[key].commission;
                s.volume = response.data.summaries[key].volume;
                break;
              }
            }
          });
        }
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'totalrebate';
      this.sorting.order = this.sorting.order || 'descending';
      this.tableData.sort((a1, a2) => {
        if (this.sorting.column == 'totalrebate') {
          return this.sorting.order == 'descending'
            ? a2['totalrebate'] - a1['totalrebate']
            : a1['totalrebate'] - a2['totalrebate'];
        }
        if (this.sorting.column == 'forex.rebate') {
          return this.sorting.order == 'descending'
            ? a2['forex']['rebate'] - a1['forex']['rebate']
            : a1['forex']['rebate'] - a2['forex']['rebate'];
        }
        if (this.sorting.column == 'commodities.rebate') {
          return this.sorting.order == 'descending'
            ? a2['commodities']['rebate'] - a1['commodities']['rebate']
            : a1['commodities']['rebate'] - a2['commodities']['rebate'];
        }
        if (this.sorting.column == 'indicies.rebate') {
          return this.sorting.order == 'descending'
            ? a2['indicies']['rebate'] - a1['indicies']['rebate']
            : a1['indicies']['rebate'] - a2['indicies']['rebate'];
        }
        if (this.sorting.column == 'crypto.rebate') {
          return this.sorting.order == 'descending'
            ? a2['crypto']['rebate'] - a1['crypto']['rebate']
            : a1['crypto']['rebate'] - a2['crypto']['rebate'];
        }
      });
    },
    queryInstruments() {
      if (!this.dataSourceId) {
        return;
      }

      //恢復預設
      this.instrumentOptions.forEach(i => {
        if (Array.isArray(i.children)) i.children = [];
      });

      apiGet_instruments({ dataSourceId: this.dataSourceId }).then(response => {
        for (let k in response.data) {
          let instrument = this.instrumentOptions.find(i => i.value == k);
          if (instrument && Array.isArray(instrument.children)) {
            instrument.children = response.data[k].sort().map(prod => {
              return { value: prod, label: prod };
            });
          }
        }
      }, this.$handleError);
    },
    sortChange(column) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
    },
    init() {
      this.queryInstruments();
      this.queryRebateReport();
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    dataSourceId() {
      return this.$store.state.behavior.dataSourceId;
    },
    operateAmountPermisison() {
      return this.$store.state.common.operateAmountPermisison;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/rebateReport.scss';
</style>
